(function ($) {
    $(document).ready(function () {
        let modalCallback = document.getElementById("Callback")
        let modalBody = $('#Callback .callback__form')

        let closeCallback = $('.close__modal')
        let linkCallBack = $('a[href^="#callback"]')

        $(linkCallBack).on('click', function (mc) {
                mc.preventDefault();
                modalCallback.style.display = "block";
                $('body').toggleClass('modal__open')
            }
        );
        $(closeCallback).on('click', function () {
                modalCallback.style.display = "none";
                $('body').removeClass('modal__open')
            }
        );

        window.onclick = function (event) {
            event.stopPropagation();
            if (event.target === modalBody) {
                modalCallback.style.display = "none"
                $('body').removeClass('modal__open')
            }
        }

        // window.onclick = function(event) {
        //     if (event.target.classList.contains('callback__form')) {
        //         for (var index in modals) {
        //             if (typeof modals[index].style !== 'undefined' && modals[index].id == open_modals[open_modals.length - 1]) {
        //                 modals[index].style.display = "none";
        //                 open_modals.pop();
        //
        //             }
        //         }
        //     }
        // }
    })
})(jQuery);
