(function ($) {
    $(document).ready(function () {
        let burger = document.querySelector('.burger');
        let mobileMenu = document.querySelector('.mobile__menu');
        let mobileMenuLink = document.querySelectorAll('.mobile__menu > .mmm__menu > li > a');
        let headerBlock = document.querySelector('header.get__header');
        let body = document.querySelector('body');

        if (burger) {
            burger.addEventListener('click', function () {
                burger.classList.toggle('active');
                mobileMenu.classList.toggle('open');
                headerBlock.classList.toggle('menu__open');
                body.classList.toggle('modal__open');
            });
        }
        $(mobileMenuLink).on('click', function () {
            burger.classList.remove('active');
            mobileMenu.classList.remove('open');
            headerBlock.classList.remove('menu__open');
            body.classList.remove('modal__open');
        })

    })
})(jQuery);
