(function ($) {
    $(document).ready(function () {

        // $(".js-faq-title").on("click", function (e) {
        //
        //     e.preventDefault();
        //     let $this = $(this);
        //
        //     if (!$this.hasClass("faq__active")) {
        //         $(".js-faq-content").slideUp(800);
        //         $(".js-faq-title").parent().removeClass("faq__active");
        //         $('.js-faq-rotate').removeClass('faq__rotate');
        //     }
        //
        //     $this.parent().toggleClass("faq__active");
        //     $this.next().slideToggle();
        //     $('.js-faq-rotate', this).toggleClass('faq__rotate');
        // });
        //
        // $('.to__tabs-content > div').hide();
        // $('.to__tabs-content > div:first').show();
        // $('.to__tabs-nav li:first').addClass('tab-active');
        // $('.to__tabs-nav a').on('click', function (tt) {
        //     tt.preventDefault();
        //     $('.to__tabs-nav li').removeClass('tab-active');
        //     $(this).parent().addClass('tab-active');
        //     $('.to__tabs-content > div').hide();
        //     $($(this).attr('href')).show();
        // });

        $('.faq__title').on("click", function (v) {
            v.preventDefault()
            let $this2 = $(this)
            $this2.parent().toggleClass("showed")
            $(this).parent().children(".faq__content").slideToggle(500);
        })
        $('.header__lang > ul > li > a').on("click", function (lang) {
            lang.preventDefault()
            // console.log('click lang')
            $('.header__lang > ul > li > ul').slideToggle(300)
        })
        $('.header__lang-mobile > ul > li > a').on("click", function (langMob) {
            langMob.preventDefault()
            // console.log('click lang')
            $('.header__lang-mobile > ul > li > ul').slideToggle(300)
        })

        let swiperClients = new Swiper('.clients__slider', {
            watchSlidesProgress: true,
            speed: 800,
            observer: true,
            observeParents: true,
            centeredSlides: true,
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                1000: {
                    slidesPerView: 5,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                500: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                400: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
            }
        })
        let swiperPartners = new Swiper('.partners__slider', {
            watchSlidesProgress: true,
            speed: 800,
            observer: true,
            observeParents: true,
            centeredSlides: true,
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                1000: {
                    slidesPerView: 5,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                500: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                400: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
            }
        })
        let swiperCooperation = new Swiper('.cooperation__slider', {
            watchSlidesProgress: true,
            speed: 800,
            observer: true,
            observeParents: true,
            centeredSlides: true,
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                1000: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                500: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                400: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
            }
        })
        let swiperVideo = new Swiper('.video__slider', {
            watchSlidesProgress: true,
            noSwiping: true,
            speed: 800,
            observer: true,
            observeParents: true,
            centeredSlides: true,
            spaceBetween: 20,
            slidesPerView: 1,
            // simulateTouch: false,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                1000: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                500: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
                400: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
            }
        })
        let swiperServices = new Swiper('.services__slider', {
            watchSlidesProgress: true,
            speed: 800,
            observer: true,
            observeParents: true,
            centeredSlides: true,
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        })
        let swiperText = new Swiper('.text__slider', {
            watchSlidesProgress: true,
            speed: 800,
            observer: true,
            observeParents: true,
            centeredSlides: true,
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        })


        let btnFileInput = $('input.js__file__input')
        if (btnFileInput.length > 0) {
            let fileCnt = 0
            let fileListBlc = $('.file__icon span')
            btnFileInput.on('change', function () {
                let file = this.files[0]
                fileCnt++
                // let html = '<li class="file_list_item">' + file.name + ';';
                // html += '</li>';
                let html = file.name + ' (Файл успешно добавлен)'
                // html = $(html).append($(this).clone())
                fileListBlc.text(html)
            });
        }
        let wow = new WOW(
            {
                boxClass: 'wow',      // animated element css class (default is wow)
                animateClass: 'animate__animated', // animation css class (default is animated)
                offset: 0,          // distance to the element when triggering the animation (default is 0)
                mobile: true,       // trigger animations on mobile devices (default is true)
                live: true,       // act on asynchronously loaded content (default is true)
                callback: function (box) {
                    // the callback is fired every time an animation is started
                    // the argument that is passed in is the DOM node being animated
                },
                scrollContainer: null // optional scroll container selector, otherwise use window
            }
        );
        wow.init();
    })
})(jQuery);

